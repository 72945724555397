import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import QuoteFormInputs from "./formInput/QuoteFormInputs";
import QuotationServices from "./services/QuoteServices";
import * as QuotationSlice from "store/modules/quotation-slice";
import { useDispatch } from "react-redux";
import { RcButton } from "components/ui/rcButton";
import { Col, Row } from "react-bootstrap";
import { customerValidator } from "./validators/customerValidator";
import { FormWatch } from "components/ui/formikFields";

function QuotationForm({ onFormSubmit, containerCss }) {
  const dispatch = useDispatch();
  const formikRef = useRef();

  const handleDeleteClick = ({ serviceTypeId, serviceId }) => {
    dispatch(QuotationSlice.removeService({ serviceTypeId, serviceId }));
  };

  const handleFormSubmit = () => {
    formikRef.current.submitForm();
  };

  return (
    <section className={containerCss}>
      <Row>
        <Col md={4}>
          <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              mobileNumber: "",
              landlineNumber: "",
              company: "",
              message: "",
              addressLine1: "",
              addressLine2: "",
              provinceState: null,
              cityMunicipality: null,
              zipcode: "",
            }}
            validationSchema={customerValidator}
            onSubmit={onFormSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <>
                <QuoteFormInputs />
                {/* <FormWatch /> */}
              </>
            )}
          </Formik>
        </Col>
        <Col>
          <QuotationServices
            containerCss="mb-3"
            onDeleteClick={handleDeleteClick}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RcButton
            variant="pill-primary"
            containerCss="mt-4 mb-4"
            disabled={false}
            onClick={handleFormSubmit}
          >
            Submit Quotation
          </RcButton>
        </Col>
      </Row>
    </section>
  );
}

QuotationForm.propTypes = {
  serviceTypes: PropTypes.array,
  containerCss: PropTypes.string,
};

QuotationForm.defaultProps = { serviceTypes: [] };

export default QuotationForm;
