import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { ContentPane } from "components/ui/contentPane";
import "./quotation.scss";
import QuotationForm from "components/containers/quotation/QuoteForm";
import * as QuotationSlice from "store/modules/quotation-slice";
import { useNavigate } from "react-router-dom";
import RcBlockUi from "components/ui/rcBlockUi/RcBlockUi";
import SeoTags from "components/features/seoTags/SeoTags";

function QuotationPage({ containerCss }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector(QuotationSlice.selectQuotation);

  const handleFormSubmit = (e) => {
    dispatch(QuotationSlice.submitQuotation(e))
      .unwrap()
      .then(() => {
        navigate("/request-quotation-completed");
      });
  };

  return (
    <RcBlockUi blocking={loading}>
      <SeoTags />
      <ContentPane
        id="quotation"
        isFullWidth={false}
        hasShadow={true}
        containerCss={`quotation-wrapper ${containerCss}`}
        contentPaddingHorizontal="3"
        contentPaddingVertical="3"
        contentCss="content-css"
        mainContentCss="px-2"
      >
        <h1>Quotation Request</h1>
        <hr />

        <QuotationForm onFormSubmit={handleFormSubmit} />
      </ContentPane>
    </RcBlockUi>
  );
}

QuotationPage.propTypes = {
  containerCss: PropTypes.string,
};

QuotationPage.defaultProps = {};

export default QuotationPage;
