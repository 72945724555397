import { Image } from "react-bootstrap";
import "./page-500.scss";
import { RcButton } from "components/ui/rcButton";
import { useNavigate } from "react-router-dom";
import SeoTags from "components/features/seoTags/SeoTags";

function Page500() {
  const navigate = useNavigate();

  const handleGoBack = () => navigate("/");

  return (
    <section className="page-500-wrapper text-center">
      <SeoTags />
      <h1 className="rca-heading1">Server Error</h1>
      <h2 className="rca-heading5">Well, this is embarassing...</h2>
      <Image src="/images/misc/page-500.webp" />
      {/* <a href="http://www.freepik.com">Designed by stories / Freepik</a> */}
      {/* <h5>It's not you, it's me.</h5> */}
      <h5>
        Sorry, this is not working properly. We now know about this mistake and
        are working to fix it.
      </h5>
      <div>
        <RcButton
          variant="pill-primary"
          size="lg"
          containerCss="my-4"
          onClick={handleGoBack}
        >
          Go Back Home
        </RcButton>
      </div>
    </section>
  );
}

export default Page500;
