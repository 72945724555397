import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
//import * as footerData from "data/footer.json";
import navBarMenus from "data/navbar-menus";
import Footer from "components/features/footer/Footer";
//import NavigationBar from "components/features/navigationBar/NavigationBar";
import Config from "config";
import RcScrollToTop from "components/ui/rcScrollToTop/RcScrollToTop";
//import HeaderRightMenus from "components/features/pluggableMenus/headerRightMenus/HeaderRightMenus";
import NavHeaderContextProvider from "components/ui/navigationHeader/context/NavHeaderContextProvider";
import RcNavBar from "components/ui/rcNavBar/RcNavBar";
import OffCanvasCustomMenus from "components/features/pluggableMenus/offCanvasCustomMenus/OffCanvasCustomMenus";
import RcNavBarFx1 from "components/ui/rcNavBar/RcNavBarFx1";
import { RcButton } from "components/ui/rcButton";
import NavBarCustomButton from "components/features/navBarCustomButton/NavBarCustomButton";
import { useAppTheme } from "hooks";
import footerData from "data/footer-data";
//import NavigationHeader from "components/features/navigationHeader/NavigationHeader";

const FullLayout = () => {
  const location = useLocation();
  const { currentTheme } = useAppTheme();

  return (
    // <NavHeaderContextProvider>
    <div className={`app-${currentTheme}`}>
      <RcNavBar
        variant="light"
        menus={navBarMenus.menus}
        brandLink="/"
        brandLogo="/images/logo/new-logo.webp"
        brandLogoHeight={80}
        brandLogoAlternateText="Rcnet Labs Logo"
        offCanvasBrandLogo="/images/logo/new-logo.webp"
        fixed="top"
        menuPlacement="end"
        burgerMenuColor="#000097"
        isOffCanvasMenu={true}
        scrollTriggerValue={80}
        offCanvasCustomComponent={<OffCanvasCustomMenus />}
        navBarRightPane={(e) => {
          return <NavBarCustomButton showOffCanvas={e?.showOffCanvas} />;
        }}
      />
      <section className="content-wrapper">
        {Config.content.allowFx ? (
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              timeout={{ enter: 250, exit: 250 }}
              classNames={"fade"}
            >
              <>
                <section className="route-section">
                  <main role="main">
                    <Outlet />
                  </main>
                </section>
              </>
            </CSSTransition>
          </TransitionGroup>
        ) : (
          <section className="route-section">
            <main role="main">
              <Outlet />
            </main>
          </section>
        )}
      </section>
      <Footer
        type={Config.footer.fatFooterType}
        copyrightText={Config.footer.copyrightText}
        data={footerData}
        //data={footerData.default}
        showFatFooter={Config.footer.showFatFooter}
      />
      <RcScrollToTop />
    </div>
    // </NavHeaderContextProvider>
  );
};

export default FullLayout;
