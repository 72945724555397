import React from "react";
import PropTypes from "prop-types";
import RcHelmet from "components/ui/rcHelmet/RcHelmet";

function SeoTags() {
  return (
    <RcHelmet
      title="Abbatoir Pest Control"
      description="Abbatoir/APMI is a full-suite Pest and Termite service provider that uses state-of-the art equipment and game-changing technology to provide cost effective pesticide solution."
      keywords="Pest Control, #1 Pest Control, Top 1 Pest Control, Top 5 Pest Control, Best Pest Control, Termite, Termite Killer, Rat Killer, Pest Killer, Termite Control Solution, Rat Abatement, 
        Above Ground Bait Device Compliment, Chemical Barrier/Reticulation Treatment, Post-Structure Soil Treatment, 
        Pre-Construction Soil Treatment, Mist Blower Industrial Treatment, Thermal Fogging, ULV Cold Fogging,  Ant Control, Cockroach Control, 
        Flies and Bugs Elimination, Pesticide, Insecticide, Garden Spraying, Rat Poisoning, Worm Elimination, Pest Control near me, Pest Control near by, Pest Control Manila, Pest Control Quezon City, Pest Control in the Philippines"
      ogTitle="Abbatoir Pest Control"
      ogUrl="https://abbatoirpest.com"
      ogImage="https://rcnetlabs-imaging.sirv.com/abbatoir/abbatoir-ads-horizontal.jpg"
      ogType="website"
      ogDescription="Abbatoir Pest Control (APMI) - Contact us for any business inquiries"
      ogLocale="en_US"
    >
      <meta property="og:image:width" content="1030" />
      <meta property="og:image:height" content="630" />
    </RcHelmet>
  );
}

export default SeoTags;
