import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentPane from "components/ui/contentPane/ContentPane";
import "./home.scss";
import RcCarousel from "components/ui/rcCarousel/RcCarousel";
import { introCarouselData } from "data/intro-carousel";
import IntroTextLeftAligned from "components/ui/rcCarousel/plugins/IntroTextLeftAligned";
import Services from "components/containers/productServices/Services";
import AboutUs from "components/features/aboutUs/AboutUs";
import MissionVision from "components/features/missionAndVision/MissionVision";
import OurTeam from "components/features/ourTeam/OurTeam";
import WhyApmi from "components/features/whyApmi/WhyApmi";
import ContactUs from "components/features/contactUs/ContactUs";
import { contactUsValidator } from "./validators/contactUsValidator";
import ClientMap from "components/features/clientMap/ClientMap";
import { useNavigate } from "react-router-dom";
import OurClients from "components/features/ourClients/OurClients";
import Gallery from "components/features/gallery/Gallery";
import CookiePolicy from "components/ui/cookiePolicy/CookiePolicy";
//import convertProvince from "core/utils/provinceMigrationTool";
import useSystem from "hooks/useSystem";
import * as QuotationSlice from "store/modules/quotation-slice";
import { Urls } from "constants/urls";
import useCookiePolicy from "hooks/useCookiePolicy";
import * as ContactUsSlice from "store/modules/contactUsSlice";
import useScrollNavigate from "hooks/useScrollNavigate";
import SeoTags from "components/features/seoTags/SeoTags";

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notify } = useSystem();

  const { showCookie, setAcceptCookie } = useCookiePolicy();
  const { loading, formId } = useSelector(ContactUsSlice.selectContactUs);

  useScrollNavigate(true, 100);

  const handleContactUsFormSubmit = (values) => {
    dispatch(ContactUsSlice.createMessage(values));
  };

  const handleHeroCtaClick = (e) => {
    if (e === "primary") navigate("/home#about-us");
    if (e === "secondary") navigate("/home#our-service");
  };

  const handleCloseCookie = () => {
    setAcceptCookie(true);
  };

  // useEffect(() => {
  //   //convertProvince();
  // }, []);

  const handleQuoteNow = (
    serviceTypeId,
    serviceTypeName,
    serviceId,
    serviceName
  ) => {
    dispatch(
      QuotationSlice.addService({
        serviceTypeId: serviceTypeId,
        serviceId: serviceId,
        serviceType: serviceTypeName,
        serviceName,
      })
    );
    navigate(Urls.QUOTATION);
  };

  const handleAddToQuote = (
    serviceTypeId,
    serviceTypeName,
    serviceId,
    serviceName
  ) => {
    dispatch(
      QuotationSlice.addService({
        serviceTypeId: serviceTypeId,
        serviceId: serviceId,
        serviceType: serviceTypeName,
        serviceName,
      })
    )
      .unwrap()
      .then(() => {
        notify("Service added to quotation list.");
      });
  };

  return (
    <section className="home-wrapper">
      <SeoTags />
      {/* <RcHelmet
        title="Abbatoir Pest Control"
        description="Abbatoir/APMI is a full-suite Pest and Termite service provider that uses state-of-the art equipment and game-changing technology to provide cost effective pesticide solution"
        keywords="Pest Control, #1 Pest Control, Top 1 Pest Control, Top 5 Pest Control, Best Pest Control, Termite, Termite Killer, Rat Killer, Pest Killer, Termite Control Solution, Rat Abatement, 
        Above Ground Bait Device Compliment, Chemical Barrier/Reticulation Treatment, Post-Structure Soil Treatment, 
        Pre-Construction Soil Treatment, Mist Blower Industrial Treatment, Thermal Fogging, ULV Cold Fogging,  Ant Control, Cockroach Control, 
        Flies and Bugs Elimination, Pesticide, Insecticide, Garden Spraying, Rat Poisoning, Worm Elimination, Pest Control near me, Pest Control near by, Pest Control Manila, Pest Control Quezon City, Pest Control in the Philippines"
      /> */}

      <RcCarousel
        fxType="scale"
        data={introCarouselData}
        indicators={false}
        pause={false}
        introTextComponent={
          <IntroTextLeftAligned
            //title="Leading Pest Control"
            title="Pest Control You Can Trust"
            subTitle="Your Shield Against Pests"
            ctaPrimaryText="Learn More"
            ctaSecondaryText="Our Services"
            onCtaClick={handleHeroCtaClick}
          />
        }
      />
      <Services onQuoteNow={handleQuoteNow} onAddToQuote={handleAddToQuote} />
      <AboutUs />
      <MissionVision />
      <WhyApmi />
      <Gallery />
      <OurClients />
      {/* <OurTeam />  */}
      <ContactUs
        formId={formId}
        loading={loading}
        validator={contactUsValidator}
        onFormSubmit={handleContactUsFormSubmit}
      />
      <ClientMap />
      <CookiePolicy show={showCookie} onCloseCookie={handleCloseCookie} />
    </section>
  );
};

export default HomePage;
