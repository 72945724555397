import React from "react";
import PropTypes from "prop-types";
import { ContentPane } from "components/ui/contentPane";
import { RcButton } from "components/ui/rcButton";
import { ReactComponent as CompletedImg } from "assets/images/quote/completed.svg";
import "./request-completed.scss";
import { useNavigate } from "react-router-dom";
import SeoTags from "components/features/seoTags/SeoTags";

function RequestCompletedPage({ containerCss }) {
  const navigate = useNavigate();

  const handleNext = () => navigate("/");

  return (
    <>
      <SeoTags />
      <ContentPane
        id="requestQuotationCompleted"
        isFullWidth={false}
        hasShadow={false}
        containerCss={`request-completed-wrapper ${containerCss}`}
        contentPaddingHorizontal="3"
        contentPaddingVertical="3"
        contentCss="content-css"
        mainContentCss="px-2"
      >
        <div className="main-container d-flex flex-column justify-content-center align-items-center text-center">
          <CompletedImg height="300px" />
          <h1>Request Quotation Completed</h1>
          <h4 className="text-muted">
            We've received your request and will call or email you back within
            24 hours.
          </h4>
          <h4 className="text-muted">Thank you!</h4>
          <RcButton
            variant="pill-primary"
            size="lg"
            containerCss="my-4"
            onClick={handleNext}
          >
            Go to Home
          </RcButton>
        </div>
      </ContentPane>
    </>
  );
}

RequestCompletedPage.propTypes = {
  containerCss: PropTypes.string,
};

RequestCompletedPage.defaultProps = {};

export default RequestCompletedPage;
