import React from "react";
import PropTypes from "prop-types";
import { RcButton } from "components/ui/rcButton";
import "./nav-bar-custom.scss";
import RequestQuotation from "components/containers/requestQuotation/RequestQuotation";
import useRequestQuotation from "hooks/useRequestQuotation";
import { PRIMARY_PHONE, CLIENT_EMAIL } from "constants/appConstants";
import { Badge } from "react-bootstrap";
import { ReactComponent as BagSvg } from "assets/images/quote/shopping-bag.svg";
import * as QuotationSlice from "store/modules/quotation-slice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Urls } from "constants/urls";
import RcTooltip from "components/ui/rcTooltip/RcTooltip";
import { ReactComponent as MailSvg } from "assets/images/feather/mail.svg";
import { ReactComponent as PhoneSvg } from "assets/images/feather/phone.svg";

function NavBarCustomButton({ showOffCanvas }) {
  const navigate = useNavigate();
  const { showRequestQuotation } = useRequestQuotation();
  const numberOfQuotations = useSelector(
    QuotationSlice.selectNumberOfQuotations
  );

  const handleRequestQuotation = () => {
    showOffCanvas(false);
    navigate(Urls.QUOTATION);
  };

  return (
    <>
      <div className="navbar-custom-button-wrapper d-none d-lg-flex flex-row align-items-center">
        <div className="d-flex flex-row justify-content-center align-items-center mx-3">
          {/* <RequestQuotation /> */}
          <i className="far fa-phone me-2" />
          <div>
            <a href={`tel:${PRIMARY_PHONE}`}>{PRIMARY_PHONE}</a>
          </div>
        </div>
        <RcButton
          variant="pill-light"
          containerCss="position-relative cart-quotation flat-button ms-3"
          onClick={handleRequestQuotation}
        >
          <BagSvg />
          {numberOfQuotations > 0 && (
            <Badge bg="danger" className="quote-count" as="div">
              {numberOfQuotations}
            </Badge>
          )}
        </RcButton>
        <RcTooltip
          message="View Quotation Request(s)"
          selector="cart-quotation"
        />
      </div>

      {/* Mobile View */}
      <div className="navbar-custom-button-wrapper d-lg-none d-flex flex-column">
        <hr />
        <div className="d-flex flex-column  mx-3 mt-2 mb-4">
          <div className="d-flex flex-row justify-content-start align-items-center mb-2">
            <PhoneSvg className="me-2" />
            <div>
              <a href={`tel:${PRIMARY_PHONE}`}>{PRIMARY_PHONE}</a>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-start align-items-center">
            <MailSvg className="me-2" />
            <div>
              <a href={`mailto:${CLIENT_EMAIL}`}>{CLIENT_EMAIL}</a>
            </div>
          </div>
        </div>
        <RcButton
          variant="outline-pill-tertiary"
          onClick={handleRequestQuotation}
        >
          <BagSvg />
          {numberOfQuotations > 0 && (
            <Badge bg="danger" as="div">
              {numberOfQuotations}
            </Badge>
          )}
          <strong className="ms-2">View Quotations</strong>
        </RcButton>
      </div>
    </>
  );
}

NavBarCustomButton.propTypes = { showOffCanvas: PropTypes.func };

NavBarCustomButton.defaultProps = {};

export default NavBarCustomButton;
