import { Image } from "react-bootstrap";
import "./notFound.scss";
import { RcButton } from "components/ui/rcButton";
import { useNavigate } from "react-router-dom";
import SeoTags from "components/features/seoTags/SeoTags";

function Page404() {
  const navigate = useNavigate();

  const handleGoBack = () => navigate("/");

  return (
    <section className="page-404-wrapper text-center">
      <SeoTags />
      <h1 className="rca-heading1">Oops!</h1>
      <h2 className="rca-heading5">Page Not Found</h2>
      <Image src="/images/misc/page-404.webp" />
      <h5>The page you've requested is not available.</h5>
      <div>
        <RcButton
          variant="pill-primary"
          size="lg"
          containerCss="my-4"
          onClick={handleGoBack}
        >
          Go Back Home
        </RcButton>
      </div>
    </section>
  );
}

export default Page404;
